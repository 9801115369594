import React from 'react'
import styled from 'styled-components'

import bgImage from './bg-praise.jpg'

function Praise() {
  return (
    <Section>
      <Title>
        Praise for <em>Shenanigans</em>
      </Title>
    </Section>
  )
}

export default Praise

const Section = styled.section`
  background-image: url(${bgImage});
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 200px;
`

const Title = styled.h1`
  color: #fff;
  font-size: 30px;
  font-weight: normal;
  margin: auto;
  font-family: 'Cardo';
  text-align: center;
`
