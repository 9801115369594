import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import PraiseHeader from '../components/PraiseHeader'
import PraiseHero from '../components/PraiseHero'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Praise for Shenanigans"
      keywords={[`Shenanigans`, `Trina Vargo`, `US-Ireland`, `US-IA`, `USIA`]}
    />
    <PraiseHeader />
    <PraiseHero />
  </Layout>
)

export default IndexPage
