import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import BookImage from '../BookImage'

import { ORDER_URL, DONATE_URL } from '../../lib/constants'

export default function HomeHero() {
  return (
    <Section>
      <Container>
        <ImageWrapper>
          <BookImage />
        </ImageWrapper>
        <ContentWrapper>
          <Description>
            {`Shenanigans: The US-Ireland Relationship in Uncertain Times review – honest, intelligent. Trina Vargo’s success in establishing the US- Ireland Alliance of great benefit to us both. `}
            <a
              href="https://www.irishtimes.com/culture/books/shenanigans-the-us-ireland-relationship-in-uncertain-times-review-honest-intelligent-1.3885209"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ruairi Quinn,
              {` `}
              <i>The Irish Times</i>
            </a>
          </Description>
          <Description>
            {`After many years of working with Vargo, it’s evident that she is deeply committed to advancing the US-Ireland Alliance. Ruairi Quinn, `}
            <i>former Minister of Education in Ireland</i>
          </Description>
          <Description>
            {`“But all new initiatives are forged in furnaces of resistance and tradition, and Vargo walked into the twin propellers of an Irish old boys’ network and parish pump politics that were loath to entertain the opinions of a forthright American woman.”  Marion McKeone, `}
            <i>Sunday Business Post</i>
          </Description>
          <Description>
            {`“This week, the Green Tide rolls in. St. Patrick’s Day is upon us and so everything must be green. Green clothes, green food, green beer. We do this because we have always done this. If it feels outdated, that’s because it is. Trina Vargo, former foreign policy adviser to Ted Kennedy, says it’s time to put away the tired Paddywhackery and forge a new relationship between the United States and Ireland, one based on modern realities, equality, and mutual self-interest.”  Kevin Cullen, `}
            <i>The Boston Globe</i>
          </Description>
          <Description>
            {`“Her portrait of the Clintons casts a shadow on a jewel of their foreign policy legacy, alleging pettiness and vengefulness after the historic peace-making of the 1998 Good Friday agreement.”  Rory Carroll, `}
            <i>The Guardian</i>
          </Description>
          <Description>
            {`“Trina Vargo's `}
            <i>Shenanigans</i>
            {/* eslint-disable-next-line no-irregular-whitespace */}
            {` is a no holds barred account of the travails of someone who has worked constructively since the beginning of the Irish peace process at the coalface of the Irish-American relationship, and whose keenest ambition has been to make it more sustainable on a realistic basis into the future.”  Martin Mansergh`}
          </Description>
          <Description>
            {`Listen to a conversation between Trina Vargo and Jeremiah Tittle, a founder of Native Creative Podcasts, about the book `}
            <a
              href="https://www.buzzsprout.com/207906/1027231-shenanigans-an-interview-with-author-trina-vargo"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Description>
          <ButtonGroup>
            <ButtonWrapper>
              <Button
                color="#008b27"
                href={ORDER_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                order now
              </Button>
              <Caption>Available now on Amazon</Caption>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                color="#005819"
                href={DONATE_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                donate
              </Button>
              <Caption>to the US-Ireland Alliance</Caption>
            </ButtonWrapper>
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

const Section = styled.section`
  background-color: #f1f6ef;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 100px 20px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

const Description = styled.p`
  color: #000;
  font-family: 'Cardo';
  font-size: 14px;
  &:last-child {
    margin-bottom: 36px;
  }
  a {
    color: #008b27;
    text-decoration: none;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  @media (max-width: 767px) {
    &:first-child {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 768px) {
    &:first-child {
      margin-right: 30px;
    }
  }
`

const Button = styled.a`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-family: 'Nunito Sans', 'sans-serif';
  height: 40px;
  justify-content: center;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;
  width: 200px;
  ${({ color }) => css`
    background-color: ${color};
    &:hover {
      background-color: ${darken(0.025, color)};
    }
  `}
`

const Caption = styled.span`
  color: #007e22;
  font-family: 'Cardo';
  font-size: 14px;
  font-style: italic;
`
